
import { Component, Prop, Vue } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import globalConfig from "src/config/index";
import { ReportService } from "src/services";

@Component
export default class OfteUser extends Vue {
  /**
   * 是否显示
   * @private
   * @returns string
   */
  @Prop({ type: Object })
  private analyzeResult: any;

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 更多历史预测
   * @private
   * @returns any
   */
  protected viewMore() {
    this.$router.push({ name: "historylist" });
  }

  /**
   * 继续答卷
   * @private
   * @returns any
   */
  protected continueAnswer() {
    let progress: any = true;
    this.$router.push({ name: "questionfill", query: { progress: progress } });
  }

  /**
   * 结束答卷
   * @private
   * @returns any
   */
  protected async stopAnswer() {
    try {
      await ReportService.instance.stopAnswering();
      this.$emit("refresh");
    } catch (error) {}
  }
}
