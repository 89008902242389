var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ofte-user"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrapper-header"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v("预测健康状况")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.analyzeResult.evaluatedTime))]),_c('div',{staticClass:"history",on:{"click":_vm.viewMore}},[_vm._v("更多历史预测 >")])]),_vm._m(0)]),_c('div',{staticClass:"appointment-user-content"},[_c('div',{staticClass:"appointment-user hea-row"},[(_vm.analyzeResult.gender == 0)?_c('img',{staticClass:"ava",attrs:{"src":"/images/user-female.png"}}):_c('img',{staticClass:"ava",attrs:{"src":"/images/user-male.png"}}),_c('div',{staticClass:"user-nr hea-row hea-flex-item1"},[_c('div',{staticClass:"hea-column hea-flex-item1"},[_c('div',{staticClass:"name hea-cl"},[_c('div',[_vm._v(_vm._s(_vm.analyzeResult.memberName))]),_c('img',{staticClass:"sex-ico",attrs:{"src":_vm.analyzeResult.gender == 0 ? '/images/sex-female.png' : '/images/sex-man.png'}})]),_c('div',{staticClass:"xx"},[_vm._v(_vm._s(_vm.analyzeResult.memberTypeStr)+" | "+_vm._s(_vm.analyzeResult.marriageStatusStr)+" | "+_vm._s(_vm.analyzeResult.age)+"岁")])])])])]),_c('div',{staticClass:"record-wrapper title-wrapper"},[_c('div',{staticClass:"check-record",class:{
          female: _vm.analyzeResult && _vm.analyzeResult.gender === 0,
          show: !_vm.isFload,
          hide: _vm.isFload,
        },attrs:{"id":"check"}},[_c('div',{staticClass:"check-record-bg",class:_vm.portrait()}),_c('div',{staticClass:"check-items check-items1",class:{
            'bg-blue': _vm.highlight == 1,
            'bg-pink': _vm.highlight == 1 && _vm.analyzeResult.gender == 0,
            'color-pink': _vm.analyzeResult.gender == 0,
          },on:{"click":function($event){return _vm.systemChange(1, 'click')}}},[_c('span',[_vm._v("生殖系统")]),_c('span',{staticClass:"system-icon system-left-icon",class:{
              'icon-blue': _vm.highlight == 1,
              'icon-pink': _vm.highlight == 1 && _vm.analyzeResult.gender == 0,
              'system-nv-icon': _vm.analyzeResult.gender == 0,
            }}),(_vm.humanDataCount.reproductiveCount)?_c('span',{staticClass:"unusual-icon"},[_vm._v(_vm._s(_vm.humanDataCount.reproductiveCount))]):_vm._e()]),_c('div',{staticClass:"check-items check-items2",class:{
            'bg-blue': _vm.highlight == 2,
            'bg-pink': _vm.highlight == 2 && _vm.analyzeResult.gender == 0,
            'color-pink': _vm.analyzeResult.gender == 0,
          },on:{"click":function($event){return _vm.systemChange(2, 'click')}}},[_c('span',[_vm._v("泌尿系统")]),_c('span',{staticClass:"system-icon system-right-icon",class:{
              'icon-blue': _vm.highlight == 2,
              'icon-pink': _vm.highlight == 2 && _vm.analyzeResult.gender == 0,
              'system-nv-icon': _vm.analyzeResult.gender == 0,
            }}),(_vm.humanDataCount.urinaryCount)?_c('span',{staticClass:"unusual-icon-right"},[_vm._v(_vm._s(_vm.humanDataCount.urinaryCount))]):_vm._e()]),_c('div',{staticClass:"check-items check-items1 check-items3",class:{
            'bg-blue': _vm.highlight == 3,
            'bg-pink': _vm.highlight == 3 && _vm.analyzeResult.gender == 0,
            'color-pink': _vm.analyzeResult.gender == 0,
          },on:{"click":function($event){return _vm.systemChange(3, 'click')}}},[_c('span',[_vm._v("消化系统")]),_c('span',{staticClass:"system-icon system-left-icon",class:{
              'icon-blue': _vm.highlight == 3,
              'icon-pink': _vm.highlight == 3 && _vm.analyzeResult.gender == 0,
              'system-nv-icon': _vm.analyzeResult.gender == 0,
            }}),(_vm.humanDataCount.digestiveCount)?_c('span',{staticClass:"unusual-icon"},[_vm._v(_vm._s(_vm.humanDataCount.digestiveCount))]):_vm._e()]),_c('div',{staticClass:"check-items check-items2 check-items4",class:{
            'bg-blue': _vm.highlight == 4,
            'bg-pink': _vm.highlight == 4 && _vm.analyzeResult.gender == 0,
            'color-pink': _vm.analyzeResult.gender == 0,
          },on:{"click":function($event){return _vm.systemChange(4, 'click')}}},[_c('span',[_vm._v("运动系统")]),_c('span',{staticClass:"system-icon system-right-icon",class:{
              'icon-blue': _vm.highlight == 4,
              'icon-pink': _vm.highlight == 4 && _vm.analyzeResult.gender == 0,
              'system-nv-icon': _vm.analyzeResult.gender == 0,
            }}),(_vm.humanDataCount.motionCount)?_c('span',{staticClass:"unusual-icon-right"},[_vm._v(_vm._s(_vm.humanDataCount.motionCount))]):_vm._e()]),_c('div',{staticClass:"check-items check-items1 check-items5",class:{
            'bg-blue': _vm.highlight == 5,
            'bg-pink': _vm.highlight == 5 && _vm.analyzeResult.gender == 0,
            'color-pink': _vm.analyzeResult.gender == 0,
          },on:{"click":function($event){return _vm.systemChange(5, 'click')}}},[(_vm.showFlag)?_c('img',{staticClass:"hand-icon",attrs:{"src":"https://hfweb.oss-cn-shenzhen.aliyuncs.com/ShuKangCloud.Personal/hand-icon.png","alt":""}}):_vm._e(),_c('span',[_vm._v("内分泌系统")]),_c('span',{staticClass:"system-icon system-left-icon",class:{
              'icon-blue': _vm.highlight == 5,
              'icon-pink': _vm.highlight == 5 && _vm.analyzeResult.gender == 0,
              'system-nv-icon': _vm.analyzeResult.gender == 0,
            }}),(_vm.humanDataCount.endocrineCount)?_c('span',{staticClass:"unusual-icon"},[_vm._v(_vm._s(_vm.humanDataCount.endocrineCount))]):_vm._e()]),_c('div',{staticClass:"check-items check-items2 check-items6",class:{
            'bg-blue': _vm.highlight == 6,
            'bg-pink': _vm.highlight == 6 && _vm.analyzeResult.gender == 0,
            'color-pink': _vm.analyzeResult.gender == 0,
          },on:{"click":function($event){return _vm.systemChange(6, 'click')}}},[_c('span',[_vm._v("呼吸系统")]),_c('span',{staticClass:"system-icon system-right-icon",class:{
              'icon-blue': _vm.highlight == 6,
              'icon-pink': _vm.highlight == 6 && _vm.analyzeResult.gender == 0,
              'system-nv-icon': _vm.analyzeResult.gender == 0,
            }}),(_vm.humanDataCount.respiratoryCount)?_c('span',{staticClass:"unusual-icon-right"},[_vm._v(_vm._s(_vm.humanDataCount.respiratoryCount))]):_vm._e()]),_c('div',{staticClass:"check-items check-items1 check-items7",class:{
            'bg-blue': _vm.highlight == 7,
            'bg-pink': _vm.highlight == 7 && _vm.analyzeResult.gender == 0,
            'color-pink': _vm.analyzeResult.gender == 0,
          },on:{"click":function($event){return _vm.systemChange(7, 'click')}}},[_c('span',[_vm._v("循环系统")]),_c('span',{staticClass:"system-icon system-left-icon",class:{
              'icon-blue': _vm.highlight == 7,
              'icon-pink': _vm.highlight == 7 && _vm.analyzeResult.gender == 0,
              'system-nv-icon': _vm.analyzeResult.gender == 0,
            }}),(_vm.humanDataCount.circulatoryCount)?_c('span',{staticClass:"unusual-icon"},[_vm._v(_vm._s(_vm.humanDataCount.circulatoryCount))]):_vm._e()]),_c('div',{staticClass:"check-items check-items2 check-items8",class:{
            'bg-blue': _vm.highlight == 8,
            'bg-pink': _vm.highlight == 8 && _vm.analyzeResult.gender == 0,
            'color-pink': _vm.analyzeResult.gender == 0,
          },on:{"click":function($event){return _vm.systemChange(8, 'click')}}},[_c('span',[_vm._v("其他")]),_c('span',{staticClass:"system-icon system-right-icon",class:{
              'icon-blue': _vm.highlight == 8,
              'icon-pink': _vm.highlight == 8 && _vm.analyzeResult.gender == 0,
              'system-nv-icon': _vm.analyzeResult.gender == 0,
            }}),(_vm.humanDataCount.otherCount)?_c('span',{staticClass:"unusual-icon-right"},[_vm._v(_vm._s(_vm.humanDataCount.otherCount))]):_vm._e()]),_c('div',{staticClass:"check-items check-items1 check-items9",class:{
            'bg-blue': _vm.highlight == 9,
            'bg-pink': _vm.highlight == 9 && _vm.analyzeResult.gender == 0,
            'color-pink': _vm.analyzeResult.gender == 0,
          },on:{"click":function($event){return _vm.systemChange(9, 'click')}}},[_c('span',[_vm._v("神经系统")]),_c('span',{staticClass:"system-icon system-left-icon",class:{
              'icon-blue': _vm.highlight == 9,
              'icon-pink': _vm.highlight == 9 && _vm.analyzeResult.gender == 0,
              'system-nv-icon': _vm.analyzeResult.gender == 0,
            }}),(_vm.humanDataCount.nervousCount)?_c('span',{staticClass:"unusual-icon"},[_vm._v(_vm._s(_vm.humanDataCount.nervousCount))]):_vm._e()])]),(_vm.abnormalList && _vm.abnormalList.length)?_c('div',{staticClass:"wrap",class:_vm.isFload ? 'hide' : 'show'},[_c('div',{staticClass:"fold-icon",on:{"click":_vm.fload}},[(_vm.isFload)?_c('img',{staticClass:"fold-hide",attrs:{"src":"https://hfweb.oss-cn-shenzhen.aliyuncs.com/ShuKangCloud.Personal/system-icon/upward.png"}}):_vm._e(),(!_vm.isFload)?_c('img',{staticClass:"fold-hide",attrs:{"src":"https://hfweb.oss-cn-shenzhen.aliyuncs.com/ShuKangCloud.Personal/system-icon/downward.png"}}):_vm._e()]),_c('div',{staticClass:"other-indicator"},[_c('p',{staticClass:"abnormal-title"},[_vm._v(_vm._s(_vm.bodySystemName)+"："+_vm._s(_vm.abnormalList.length)+"项风险")]),_c('div',{staticClass:"abnormal-content"},_vm._l((_vm.abnormalList),function(abnormalItem,index){return _c('span',{key:index,staticClass:"abnormal-item"},[_c('div',{staticClass:"abnormal-wrapper"},[_c('img',{attrs:{"src":require("@/assets/img/risk-icon.png"),"alt":""}}),_vm._v(" "+_vm._s(abnormalItem.name)+" ")]),_c('div',{staticClass:"abnormal-text",on:{"click":function($event){return _vm.showTip(abnormalItem.desc, abnormalItem.name)}}},[_c('p',{staticClass:"abnormal-te"},[_vm._v(" "+_vm._s(abnormalItem.desc)+" ")]),(abnormalItem.desc.length > 30)?_c('van-icon',{staticClass:"right-icon",attrs:{"color":"#333","size":"15","name":"arrow"}}):_vm._e()],1)])}),0)])]):_c('div',{staticClass:"wrap-nodata"},[_vm._m(1)])]),(_vm.isShowDialog)?_c('udialog',{attrs:{"title":_vm.dialogName,"content":_vm.dialogContent},on:{"close":function($event){_vm.isShowDialog = false}}}):_vm._e()],1),_c('div',{staticClass:"btn-list-wrapper"},[_c('div',{staticClass:"prev-page",on:{"click":_vm.customizeAgain}},[_vm._v("再次定制")]),_c('div',{staticClass:"next-page",on:{"click":_vm.viewCuspackage}},[_vm._v("查看定制套餐")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bottom"},[_c('span',[_vm._v("仅为风险预测,不作为诊断依据,如身体不适请前往医院就医。")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nodata-content"},[_c('img',{staticClass:"file-exception",attrs:{"src":"https://hfweb.oss-cn-shenzhen.aliyuncs.com/ShuKangCloud.Personal/file%20-exception.png","alt":""}}),_c('span',{staticClass:"no-resolve"},[_vm._v("暂无风险~ ")])])
}]

export { render, staticRenderFns }