
import { Component, Vue } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import globalConfig from "src/config/index";
import { ReportService } from "src/services";

@Component
export default class OfteUser extends Vue {
  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
  protected created() {
    setTimeout(() => {
      this.$router.replace({ name: "aicustomized" });
    }, 1000);
  }
}
