
import { Component, Vue } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import globalConfig from "src/config/index";
import { PackageService, ReportService, OrderService } from "src/services";
import packageCompare from "../package/sub/package-compare.vue";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component({
  components: {
    packageCompare,
  },
})
export default class OfteUser extends Vue {
  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  private showOverlay = false;

  private appointmentOrder = false;

  /**
   * 显示套餐对比
   * @protected
   * @returns boolean
   */
  public showComparePopup: boolean = false;

  /**
   * 监听页面的滚动
   * @private
   * @returns number
   */
  private onscroll: boolean = false;

  /**
   * 人员id
   * @private
   * @returns string
   */
  private packageList = [];

  /**
   * 人员id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.employerMemberId as string)) || "";
  }

  /**
   * 获取企业参数配置
   * @private
   * @returns any
   */
  private get generalSettings(): any {
    let generalSettingsValue = localStorage.getItem("generalSettings");
    if (generalSettingsValue) {
      return JSON.parse(generalSettingsValue);
    }
    return [];
  }

  /**
   * 是否显示配置
   * @protected
   * @returns boolean
   */
  protected showStting(code: string): boolean {
    // A1001 -- 体检套餐列表页、详情页、提交订单页及订单详情页均不显示体检套餐价格及企业报销金额。
    // A1002 -- 体检套餐列表页、详情页均不显示体检套餐划线价格。
    let data = this.generalSettings.filter((res) => res.code == code);
    if (data.length > 0) {
      return data[0].isOpen;
    } else {
      return true;
    }
  }

  //滑动隐藏条件
  protected handleScroll() {
    this.onscroll = true;
  }

  protected handleScrollEnd() {
    this.onscroll = false;
  }

  /**
   * 返回
   */
  private customFn() {
    this.backChange();
  }

  /**
   * 问卷id
   * @private
   * @returns string
   */
  private get questionnaireRId(): string {
    return (this.$route.query && (this.$route.query.questionnaireRId as string)) || "";
  }

  private viewOrgList(id) {
    this.$router.push({ name: "orgList", query: { packageId: id } });
  }

  /**
   * 关闭弹窗
   * @protected
   * @returns void
   */
  protected closeComparePopup() {
    this.showComparePopup = false;
  }

  /**
   * 退出
   * @private
   * @returns void
   */
  private laterView() {
    this.showOverlay = false;
    this.$router.go(-1);
  }

  /**
   * 在考虑下
   * @private
   * @returns void
   */
  private onAgree() {
    this.showOverlay = false;
    return;
  }

  private mounted() {
    this.getPackageList();
    this.checkHasEffOrder();

    // 挂载的时候添加滚动事件
    window.addEventListener("touchmove", this.handleScroll, true);
    window.addEventListener("touchend", this.handleScrollEnd, true);
  }

  /**
   * 获取套餐列表
   * @private
   * @returns void
   */
  private async getPackageList() {
    try {
      let { content: result } = await PackageService.instance.getPackageList(this.id, this.questionnaireRId);
      this.packageList = result.data.packageList;
    } catch (error) {}
  }

  /**
   * 是否有有效订单
   * @private
   * @returns void
   */
  private async checkHasEffOrder() {
    try {
      let { content: result } = await OrderService.instance.checkHasEffOrder(this.id);
      this.appointmentOrder = result.data;
    } catch (error) {}
  }

  private backChange() {
    this.showOverlay = true; //逻辑
  }
}
