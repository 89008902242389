
import { Component, Vue } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import globalConfig from "src/config/index";
import { ReportService } from "src/services";

@Component
export default class OfteUser extends Vue {
  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 免费定制
   * @private
   * @returns void
   */
  private async freeCustomized(): Promise<void> {
    try {
      let { content: result } = await ReportService.instance.checkQuestion();
      this.$router.push({ name: "relatedReport" });
    } catch (err) {
      Toast(err);
    }
  }
}
